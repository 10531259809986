.accordion-container {
    position: relative;
    background: transparent;
    /* overflow-y: scroll; */
}

.accordion-card {
    margin: 0.5rem 0;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px !important;
}

.accordion-card__header {
    background: transparent;
    border-bottom: none;
}

.accordion-card__body {
    color: black;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 400;
    font-size: 18px;
    white-space: pre-wrap;
    padding: 0;
    margin: auto 0.5rem auto 0.5rem;
}

.header-button {
    outline: none;
    border: none;
    color: black;
    width: 100%;
    background-color: white;
    text-align: start;
    font-size: 20px;
    margin-left: 1rem;
}

.accordion-card__header-items {
    display: flex;
    align-items: center;
}

.online .mdi-checkbox-blank-circle {
    color: #007bff;
    font-size: 16px;
    transition: all 0.5s;
}

.offline .mdi-checkbox-blank-circle {
    color: #f5f4f7;
    font-size: 16px;
    transition: all 0.5s;
}

.mdi-chevron-up,
.mdi-chevron-down {
    color: black;
    font-size: 30px;
}

.accordion-search {
    position: absolute;
    bottom: 14.5%;
    right: 4.6%;
}
