@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

/* Chatstyles.css */
:root {
    --chat-background: rgba(10, 14, 14, 0.95);
    --chat-panel-background: #131719;
    --chat-bubble-background: #14181a;
    --chat-bubble-active-background: #171a1b;
    --chat-add-button-background: #212324;
    --chat-send-button-background: #8147fc;
    --chat-text-color: #a3a3a3;
    --chat-options-svg: #a3a3a3;
}

.chat__conversation-board {
    height: calc(80vh - 24vh); 
    overflow-y: auto;
    /* padding: 1vh 20px; */
    font-family: 'Kalam', cursive;
}

.chat__conversation-board::-webkit-scrollbar {
    width: 2vw;
    font-family: 'Kalam', cursive;
}

.chat__conversation-board::-webkit-scrollbar-track {
    background: rgb(34, 45, 55);
    font-family: 'Kalam', cursive;
}

.chat__conversation-board::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1em solid rgb(46, 95, 138);
    font-family: 'Kalam', cursive;
}

.chat__conversation-board__message__person__nickname,
.chat__conversation-board__message__bubble span {
    font-family: 'Kalam', cursive;
}

#chat {
    background: rgb(27 36 44);
    top: 5vh;
    margin: 1vh auto;
    padding: 1vh;
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1.5rem 0rem rgba(0, 0, 0, 0.15);
    min-height: calc(50vh);
    font-family: 'Kalam', cursive;
}

#chat::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(27, 36, 44);
    z-index: -1;
    min-height: calc(50vh);
    font-family: 'Kalam', cursive;
}

.chat__conversation-panel {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    background: #11171c;
    margin-left:-10px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    font-family: 'Kalam', cursive;
}

.chat__conversation-panel__input {
    margin-right: 0.5rem;
    padding: 0.5rem;
    border: 2px solid #ddd;
    border-radius: 0.25rem;
    outline: none;
    flex: 1;
    font-family: 'Kalam', cursive;
}

.chat__conversation-panel__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    /* background-color: var(--chat-send-button-background); */
    background-color: #1f5c44;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background 0.3s ease;
    font-family: 'Kalam', cursive;
}

.chat__conversation-panel__button svg {
    width: 24px;
    height: 24px;
    color: white;
    /* font-family: 'Kalam', cursive; */
}

.chat__conversation-panel__button:hover {
    background-color: rgb(27, 158, 7);
    font-family: 'Kalam', cursive;
}

/* Styles for message bubbles */
.chat__conversation-board__message__bubble {
    padding: 10px 20px; 
    border-radius: 18px; 
    margin: 5px 0;
    max-width: 70%; 
    word-wrap: break-word;
    position: relative; 
    font-family: 'Kalam', cursive;
}

/* Casebook AI messages (left-aligned) */
.chat__conversation-board__message-container.me {
    /* display: flex; */
    justify-content: flex-start;
}

.chat__conversation-board__message-container.me .chat__conversation-board__message__bubble {
    background-color: #463046d6; 
    color: #ffffff; 
    max-width:1000px;
}

/* User messages (right-aligned) */
.chat__conversation-board__message-container.user {
    /* display: flex; */
    justify-content: flex-end;
}

.chat__conversation-board__message-container.user .chat__conversation-board__message__bubble {
    background-color: #e0e0e0;
    color: #000;
}

.chat__conversation-board__message__person__nickname {
    margin: 0 10px;
}

.chat__conversation-board__message-container:not(:last-child) {
    margin-bottom: 2vh;
}
