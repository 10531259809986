// /* Cards */

/* stacking but no scroll working */
// .newcard {
//   position: sticky;
//   top: 0; // Start at top, will adjust for individual cards if needed
//   background: rgb(27, 36, 44);
//   padding: 1.5rem;
//   box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15);
//   border-radius: $card-border-radius; // Use variable for consistency
//   margin-bottom: 2rem; // Add space between cards
// }
//   // Ensuring each card's top is slightly adjusted to create the sticky layered effect
//  @for $i from 1 through 20 {
//   .newcard:nth-child(#{$i}) {
//     top: #{$i }; // This computes each card's top value based on its index
//   }
// }

/* individual scroll working */
.newcard {
  display: none; // Initially set all cards to not display
  position: sticky;
  top: 0; // All cards start from the top
  background: rgb(27, 36, 44);
  padding: 0.7rem;
  // box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 0rem rgba(0, 0, 0, 0.15);
  border-radius: $card-border-radius;
  margin-bottom: 2rem;
  height:15vh;
  &.active {
    display: block; // Only the active card is displayed
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar__highlights::-webkit-scrollbar {
  display: none;
  
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar__highlights {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

// .description
// .newcard {
//   display: none; // Initially set all cards to not display
//   position: sticky;
//   top: 0; // All cards start from the top
//   background: rgb(27, 36, 44);
//   padding: 1.5rem;
//   box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15);
//   border-radius: $card-border-radius;
//   margin-bottom: 2rem;

//   &.active {
//     display: block; // Only the active card is displayed
//   }

//   &:nth-child(1).active {
//     top: 0; // First card stays at the top
//   }

//   &:nth-child(2).active {
//     top: 20px; // Second card is slightly lower
//   }

//   &:nth-child(n+3).active {
//     top: 40px; // Third and subsequent cards stack on top of the second one
//   }
// }


.card {
  border-radius: $card-border-radius;
  .card-body {
    padding: $card-padding-y $card-padding-x;
    + .card-body {
      padding-top: 1rem;
    }
  }
  .card-title {
    color: $card-title-color;
    margin-bottom: 1.125rem;
    text-transform: capitalize;
  }
  .card-subtitle {
    @extend .text-gray;
    font-weight: normal;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  
  }
  .card-description {
    color: $card-description-color;
    font-weight: $font-weight-light;
  }
  &.card-outline-success {
		border: 1px solid theme-color("success");
	}
	&.card-outline-primary {
		border: 1px solid theme-color("primary");
	}
	&.card-outline-warning {
		border: 1px solid theme-color("warning");
	}
	&.card-outline-danger {
		border: 1px solid theme-color("danger");
	}
	&.card-rounded {
		@include border-radius(5px);
	}

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top:0;
      right: 0;
      height: 100%;
    }
  }
  &.corona-gradient-card {
    background-image: linear-gradient(to left, #d41459, #911a6c);
    border-radius: 6px;
    .gradient-corona-img {
      height: 85px;
      @media (max-width: 576px) {
        height: auto;
        min-height: 50px;
        max-height: 85px;
      }
    }
    @media (max-width: 576px) {
     h4 {
       font-size: 0.75rem;
     }
    }
  }
}


@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
  }
}
